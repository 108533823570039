import axios from 'axios';
import loadStateFromQuery from '@/FreeTrial/store/actions/load-state-from-query.js';
import loadStateFromDB from '@/FreeTrial/store/actions/load-state-from-db.js';
import loadStateFromCookie from '@/FreeTrial/store/actions/load-state-from-cookies.js';
import sharedSetTracking from '@/FreeTrial/store/actions/set-tracking.js';
import checkPartner from '@/FreeTrial/store/actions/check-partner.js';

import submitTrial from '@/FreeTrial/store/actions/submit-trial.js';

import { KEAP_PRO_TRIAL_EDITION_NAME } from '@/Util/constants.js';

export default {
    async submitTrial({ state, commit, getters }) {
        await submitTrial(state, commit, getters);
    },
    async returnTestBoxUser({ state, commit, getters }) {
        window.Sentry.setUser(state.userInfo.email);
        const { data } = await axios.post('/api/v1/subscription/returning-trial', {
            email: getters.userInfo.email,
        });
        commit('updateRedirectLocation', data.location);

        return data.location;
    },
    async checkTestBox({ state, commit, getters }) {
        window.Sentry.setUser(state.userInfo.email);
        const { data } = await axios.post('/api/v1/lookup/testbox', {
            email: state.userInfo.email,
        });

        return data.existing;
    },
    async captureLead( { commit, state }, ftAbandonment = false) {
        window.Sentry.setUser(state.userInfo.email);
        let { data } = await axios.post('/api/v1/capture-lead', {
            email: state.userInfo.email,
            ...(!!state.userInfo.firstName && {firstName: state.userInfo.firstName}),
            ...(!!state.userInfo.lastName && {lastName: state.userInfo.lastName}),
            ...(!!state.userInfo.phone && {phone: state.userInfo.phone}),
            requestCampaign: ftAbandonment ? 'architecture.FreeTrialAbandonment' : 'architecture.FreeTrialTestBox',
            ...(!!state.tracking.googleClickId && {googleClickId: state.tracking.googleClickId}),
            ...(!!state.tracking.googleClientId && {googleClientId: state.tracking.googleClientId}),
            ...(!!state.tracking.googleTrackId && {googleTrackId: state.tracking.googleTrackId}),
            ...(!!state.tracking.utmCampaign && {utmCampaign: state.tracking.utmCampaign}),
            ...(!!state.tracking.utmContent && {utmContent: state.tracking.utmContent}),
            ...(!!state.tracking.utmMedium && {utmMedium: state.tracking.utmMedium}),
            ...(!!state.tracking.utmPlacement && {utmPlacement: state.tracking.utmPlacement}),
            ...(!!state.tracking.utmReferrer && {utmReferrer: state.tracking.utmReferrer}),
            ...(!!state.tracking.utmSource && {utmSource: state.tracking.utmSource}),
            ...(!!state.tracking.utmTerm && {utmTerm: state.tracking.utmTerm}),
            // lastLeadSource: state.tracking.lastLeadSource, //? This doesn't appear to be set on the original cart however we can use them if we read the k_lrls cookie
            // leadSource: state.tracking.leadSource, //? This doesn't appear to be set on the original cart however we can use them if we read the k_ls cookie
        });
        return data;
    },
    async captureEmail( { commit, state }) {
        window.Sentry.setUser(state.userInfo.email);
        let { data } = await axios.post('/api/v1/capture-email', {
            email: state.userInfo.email,
            ...(!!state.tracking.googleClickId && {googleClickId: state.tracking.googleClickId}),
            ...(!!state.tracking.googleClientId && {googleClientId: state.tracking.googleClientId}),
            ...(!!state.tracking.googleTrackId && {googleTrackId: state.tracking.googleTrackId}),
            ...(!!state.tracking.utmCampaign && {utmCampaign: state.tracking.utmCampaign}),
            ...(!!state.tracking.utmContent && {utmContent: state.tracking.utmContent}),
            ...(!!state.tracking.utmMedium && {utmMedium: state.tracking.utmMedium}),
            ...(!!state.tracking.utmPlacement && {utmPlacement: state.tracking.utmPlacement}),
            ...(!!state.tracking.utmReferrer && {utmReferrer: state.tracking.utmReferrer}),
            ...(!!state.tracking.utmSource && {utmSource: state.tracking.utmSource}),
            ...(!!state.tracking.utmTerm && {utmTerm: state.tracking.utmTerm}),
            // lastLeadSource: state.tracking.lastLeadSource, //? This doesn't appear to be set on the original cart however we can use them if we read the k_lrls cookie
            // leadSource: state.tracking.leadSource, //? This doesn't appear to be set on the original cart however we can use them if we read the k_ls cookie
        });
        return data;
    },
    async submitReconsideration({ commit, state }, payload) {
        const { data } = await axios.post('/api/v1/message/reconsideration', {
            ...payload,
            app: 'FreeTrial',
        });
        return data;
    },
    async sendNewFTNotification({ state }, appName = null) {
        const { data } = await axios.post('/api/v1/message/new-ft', {
            ...appName,
            firstName: state.userInfo.firstName,
            lastName: state.userInfo.lastName,
            email: state.userInfo.email,
            phone: state.userInfo.phone,
        });
        return data;
    },
    async loadAccountInfo({ commit, getters }) {
        const { data } = await axios.post('/api/v1/lookup/account-info', {
            token: getters.casJWT,
        });
        //TODO: Error handling
        const activeFtApp = data.content.find((app) => {
            return app.account.edition.name === KEAP_PRO_TRIAL_EDITION_NAME && app.account.status.toLowerCase() === 'active';
        });
        if (!!activeFtApp) {
            await commit('updateReturningCustomer', {
                hasActiveFreeTrial: true,
                appId: activeFtApp.name
            });
        } else {
            await commit('updateReturningCustomer', {
                hasActiveFreeTrial: false
            });
        }
        return data;
    },
    async loadStateFromQuery({ commit }, query) {
        await loadStateFromQuery(commit, query);
    },
    async loadStateFromDB({ commit, getters }) {
        await loadStateFromDB(commit, getters);
    },
    async checkPartner({ commit, getters }) {
        await checkPartner(commit, getters);
    },
    loadStateFromCookie({ commit, getters, state}) {
        loadStateFromCookie(commit, getters, state);
    },
    setTracking({ commit }, query) {
        sharedSetTracking(commit, query);
    },
};
