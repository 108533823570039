import { getCookie, poll } from '@/Util/helpers.js';
import {
    UTM_COOKIE,
    REFERRER_COOKIE,
    GCLID_COOKIE,
    PARTNERSTACK_COOKIE,
    MARKETO_COOKIE,
    GA_CLIENT_ID
} from '@/Util/constants.js'


export default async (commit, query) => {
    const trackingProps = {};

    const fallbackValue = 'none';

    if (query.has('affiliateCode')) trackingProps.affiliateCode = query.get('affiliateCode') || '';
    if (query.has('linkPostedBy')) trackingProps.linkPostedBy = query.get('linkPostedBy');
    if (query.has('bundleId')) trackingProps.bundleIds = query.get('bundleId');
    if (query.has('bundleids')) trackingProps.bundleIds = query.get('bundleids');
    // if (query.has('REPLACE')) trackingProps.REPLACE = query.get('REPLACE');

    const referrerCookie = getCookie(REFERRER_COOKIE);

    if (referrerCookie) {
        trackingProps.utmReferrer = window.atob(referrerCookie);
    } else {
        trackingProps.utmReferrer = document.referrer || '';
    }

    const gclidCookie = getCookie(GCLID_COOKIE);

    if (gclidCookie) {
        trackingProps.googleClickId = gclidCookie;
    } else if (query.has('gclid')) {
        trackingProps.googleClickId = query.get('gclid');
    }

    /* Check for cookied UTM values */

    const utmCookieContents = getCookie(UTM_COOKIE);

    if (utmCookieContents) {
        try {
            const utmParameters = { ...JSON.parse(decodeURIComponent(utmCookieContents)) };

            trackingProps.utmCampaign = utmParameters.utm_campaign || fallbackValue;
            trackingProps.utmSource = utmParameters.utm_source || fallbackValue;
            trackingProps.utmMedium = utmParameters.utm_medium || fallbackValue;
            trackingProps.utmTerm = utmParameters.utm_term || fallbackValue;
            trackingProps.utmContent = utmParameters.utm_content || fallbackValue;
            trackingProps.utmPlacement = utmParameters.placement || fallbackValue;
            if (!trackingProps.affiliateCode) {
                trackingProps.affiliateCode = utmParameters.affiliateCode || '';
            }
        } catch (e) {
            /* Cookie parse failed, not much we can do here. May be malformed. */
        }
    }

    /* We prefer UTMs in the URL over the cookie, if we have them. */

    if (query.has('utm_campaign')) trackingProps.utmCampaign = query.get('utm_campaign');
    if (query.has('utm_source')) trackingProps.utmSource = query.get('utm_source');
    if (query.has('utm_medium')) trackingProps.utmMedium = query.get('utm_medium');
    if (query.has('utm_term')) trackingProps.utmTerm = query.get('utm_term');
    if (query.has('utm_content')) trackingProps.utmContent = query.get('utm_content');
    if (query.has('placement')) trackingProps.utmPlacement = query.get('placement');
    if (query.has('affiliateCode')) trackingProps.affiliateCode = query.get('affiliateCode');

    const partnerStackCookie = getCookie(PARTNERSTACK_COOKIE);

    if (partnerStackCookie) {
        try {
            const partnerStack = { ...JSON.parse(decodeURIComponent(partnerStackCookie)) };

            if (partnerStack.group_name) trackingProps.psGroupName = partnerStack.group_name;
            if (partnerStack.partner_key) trackingProps.psPartnerKey = partnerStack.partner_key;
        } catch (e) {
            /* Cookie parse failed, not much we can do here. May be malformed. */
        }
    }

    /* PartnerStack query parameters */

    if (query.has('group_name') || query.has('partner_key')) {
        /* Ensure that we don't mix partner values from the cookie */
        delete trackingProps.psGroupName;
        delete trackingProps.psPartnerKey;
    }
    if (query.has('group_name')) trackingProps.psGroupName = query.get('group_name');
    if (query.has('partner_key')) trackingProps.psPartnerKey = query.get('partner_key');

    /* Marketo token cookie */

    const marketoToken = getCookie(MARKETO_COOKIE);

    if (marketoToken) trackingProps.marketoToken = marketoToken;

    /* Push all query parameters */

    const allQueryParams = {};

    query.forEach((val, key) => {
        allQueryParams[key] = val;
    })

    trackingProps.queryParameters = allQueryParams;

    const gaClientId = getCookie(GA_CLIENT_ID);
    if (!!gaClientId) {
        trackingProps.googleClientId = gaClientId.substring(6);
        trackingProps.googleTrackId = 'G-Q69FT9FQEX';
    }

    if (Object.keys(trackingProps).length > 0) {
        commit('updateTracking', trackingProps);
    }
}
