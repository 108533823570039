import { getCookie } from '@/Util/helpers.js';
import { PARTNER_DISCOUNT, PARTNER_ID, UTM_COOKIE } from '@/Util/constants.js';

export default (commit, getters) => {
    const partnerIdCookieVal = getCookie(PARTNER_ID);
    let utm = getCookie(UTM_COOKIE);
    const adPartner = getCookie(PARTNER_DISCOUNT);

    if (!getters.adPartner && !!adPartner) {
        let adPartnerObj = JSON.parse(adPartner);
        commit('updatePurchaseInfo', { sourceCode: adPartnerObj.name });
    }

    if (!getters.partnerAccountId && !!partnerIdCookieVal) {
        let partnerAccountId = partnerIdCookieVal;
        commit('updatePurchaseInfo', { partnerAccountId });
    }

    if (!!utm) {
        try {
            utm = JSON.parse(decodeURIComponent(utm));
            commit('updatePurchaseInfo', { partnerAccountId: getters.partnerAccountId || utm.partnerAccountId || '', linkPostedBy: getters.linkPostedBy || utm.linkPostedBy || ''});
        } catch(e) {
            // Couldnt parse cookie data.
        }
    }

}
