export default {
    freeTrialPayload( state ) {
        return {
        }
    },
    userInfo( state ) {
        return state.userInfo;
    },
    hasActiveError( state ) {
        return state.error.active;
    },
    passwordValid( state ) {
        return state.passwordIsValid;
    },
    errorCode( state ) {
        return state.error.code;
    },
    errorMessage( state ) {
        return state.error.message || '';
    },
    password( state ) {
        return state.userInfo.password;
    },
    submitting( state ) {
        return state.orderStatus.submitting;
    },
    success( state ) {
        return state.orderStatus.success;
    },
    instantOn( state ) {
        return state.instantOn || false;
    },
    casJWT( state ) {
        return state.returningCustomer.casJWT;
    },
    hasActiveApp( state ) {
        return state.returningCustomer.hasActiveFreeTrial;
    },
    existingAppId( state ) {
        return state.returningCustomer.appId;
    },
    recaptchaToken( state ) {
        return state.recaptchaToken;
    },
    hasActiveError( state ) {
        return state.error.active;
    },
    errorCode( state ) {
        return state.error.code;
    },
    errorMessage( state ) {
        return state.error.message || '';
    },
    submitting( state ) {
        return state.orderStatus.submitting;
    },
    success( state ) {
        return state.orderStatus.success;
    },
    partnerAccountId( state ) {
        return state.purchaseInfo.partnerAccountId;
    },
    linkPostedBy( state ) {
        return state.purchaseInfo.linkPostedBy;
    },
    isPartner( state ) {
        return !!state.purchaseInfo.partnerAccountId;
    },
    adPartner( state ) {
        return state.purchaseInfo.sourceCode;
    },
    isLoading( state ) {
        return state.loading;
    },
    isTestBox( state, getters ) {
        if ((!!Keap.env.VITE_DISABLE_TESTBOX && Keap.env.VITE_DISABLE_TESTBOX.toLowerCase() === 'true') || (!!state.purchaseInfo.flow && state.purchaseInfo.flow === 'standard')) {
            return false;
        }

        return !getters.isPartner;
    },
    isMaintenance( state ) {
        if (state.purchaseInfo.bundleId === 'TESTING') {
            return true;
        }

        if (!!state.purchaseInfo.bundleId) {
            const now = new Date();
            const startTime = new Date(Date.UTC(2024, 2, 16, 10, 0, 0)); // 10 AM UTC = 3 AM MST
            const endTime = new Date(Date.UTC(2024, 2, 16, 11, 0, 0)); // 11 AM UTC = 4 AM MST

            if (now >= startTime && now <= endTime) {
                return true;
            }
        }

        return false;
    },
    subscriptionId( state ) {
        return state.chargebeeInfo.subscriptionId;
    },
    redirectLocation( state ) {
        return state.redirectLocation;
    },
    transactionId( state ) {
        return state.transactionId;
    },
    slug( state ) {
        const url = new URL(window.location.href);
        const slug = url.pathname.split('/').pop();
        return slug;
    }
};
