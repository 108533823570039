<template>
    <Component
        v-if="hasError"
        :is="getModal"
        :noClose="noClose"
        :qa-name="`${toKebab(getModal)}_modal`"
        @closeModal="closeModal"
    />
</template>

<script>
import { defineAsyncComponent } from 'vue';

const Blocked = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/Blocked.vue'));
const AccountExists = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/AccountExists.vue'));
const AccountExistsTestBox = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/AccountExistsTestBox.vue'));
const AccountDoesNotExistTestBox = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/AccountDoesNotExistTestBox.vue'));
const BypassFailed = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/BypassFailed.vue'));
const CountryNotAllowed = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/CountryNotAllowed.vue'));
const CheckEmail = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/CheckEmail.vue'));
const Dynamic = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/Dynamic.vue'));
const General = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/General.vue'));
const GeneralTestBox = defineAsyncComponent(() => import('@/FreeTrial/Components/Modals/GeneralTestBox.vue'));

export default {
    components: {
        Blocked,
        AccountExists,
        AccountExistsTestBox,
        AccountDoesNotExistTestBox,
        Dynamic,
        General,
        GeneralTestBox,
        BypassFailed,
        CheckEmail,
        CountryNotAllowed,
    },
    props: {
        noClose: {
            type: Boolean
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        closeModal() {
            this.$store.commit('setError', {
                active: false,
                code: 0
            });
        },
        toKebab(string) {
            return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
        }
    },
    computed: {
        hasError() {
            return this.$store.getters.hasActiveError;
        },
        getModal() {
            const ERRORS = window.constants.errorCodes;
            if (this.$store.getters.hasActiveError) {
                switch(this.$store.getters.errorCode) {
                    case ERRORS.ehawkBlocked:
                    case ERRORS.recaptchaBlocked:
                        return 'Blocked';
                    case ERRORS.trialExists:
                        return 'AccountExists';
                    case ERRORS.testBoxExists:
                        return 'AccountExistsTestBox';
                    case ERRORS.testBoxDoesNotExist:
                        return 'AccountDoesNotExistTestBox';
                    case ERRORS.bypassFailed:
                        return 'BypassFailed';
                    case ERRORS.checkEmail:
                        return 'CheckEmail';
                    case ERRORS.countryNotAllowed:
                        return 'CountryNotAllowed';
                    case ERRORS.generalTestBox:
                        return 'GeneralTestBox';
                    case 'dynamic':
                        return 'Dynamic';
                    default:
                        return 'General';
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
