export default {
    userInfo: {
        firstName: '',
        email: '',
        lastName: '',
        password: '',
        phone: '',
        currency: window.constants.currencies.USD,
        country: 'US',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
        userId: '',
        jwt: '',
    },
    purchaseInfo: {
        partnerAccountId: '',
        linkPostedBy: '',
        bundleId: '',
        sourceCode: '',
        partnerName: '',
        flow: '',
        linkId: '',
    },
    talon: '{\'version\': 6, \'status\': -1}',
    orderStatus: {
        submitting: false,
        success: false,
    },
    returningCustomer: {
        hasActiveFreeTrial: false,
        appId: '',
        casJWT: '',
    },
    error: {
        active: false,
        message: '',
        code: 0,
    },
    redirectLocation: '',
    passwordIsValid: false,
    canSubmit: false,
    instantOn: true,
    tracking: {},
    loading: false,
    recaptchaToken: '',
    testBox: false,
    transactionId: '',
};
