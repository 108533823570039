export default async (commit, getters) => {
    const purchaseInfo = {};
    const userInfo = {};

    // Get the slug from the URL
    const url = new URL(window.location.href);
    const slug = url.pathname.split('/').pop();

    // Fetch the link data
    let response;

    try {
        response = await axios.get(`/api/v1/links/${getters.slug}`);
    } catch (error) {
        window.Sentry.logError(error);
        // ? Do we need to warn or stop the user if the link doesnt load?
        commit('setError', {
            active: true,
            code: 'dynamic',
            message: 'There was an error trying to load this trial link.'
        });
        return;
    }
    if (!response?.data?.is_trial) {
        // ? Do we need to warn the user that this link is invalid and let them in or stop them completely?
        commit('setError', {
            active: true,
            code: 'dynamic',
            message: 'There was an error trying to load this trial link.'
        });
        return;
    }

    if (!!response?.data?.country) userInfo.country = response?.data?.country.toUpperCase();
    if (!!response?.data?.currency) userInfo.currency = response?.data?.currency.toUpperCase();

    // Set the IDs (Partner, Bundle, Event, Quote, Purchase, PostedBy)
    purchaseInfo.linkId = window.location.pathname.replace(/^\//, '');
    if (!!response?.data?.partner_id) purchaseInfo.partnerAccountId = response?.data?.partner_id;
    if (!!response?.data?.bundle_id) purchaseInfo.bundleId = response?.data?.bundle_id;
    if (!!response?.data?.posted_by_id) purchaseInfo.linkPostedBy = response?.data?.posted_by_id;
    // End of setting IDs

    // Set the source code and partner name
    if (!!response?.data?.source_code) purchaseInfo.sourceCode = response?.data?.source_code.toLowerCase();
    if (!!response?.data?.partner_name) purchaseInfo.partnerName = response?.data?.partner_name.toLowerCase();
    // End of setting source code and partner name

    // Set the flow (standard, testbox, etc.)
    if (!!response?.data?.user_flow) purchaseInfo.flow = response?.data?.user_flow || 'standard';

    // Prefill user data if set
    if (!!response?.data?.email) userInfo.email = response?.data?.email;
    if (!!response?.data?.first_name) userInfo.firstName = response?.data?.first_name;
    if (!!response?.data?.last_name) userInfo.lastName = response?.data?.last_name;
    if (!!response?.data?.phone) userInfo.phone = response?.data?.phone;
    if (!!response?.data?.company) userInfo.company = response?.data?.company;
    // End of prefill

    // Commit everything to state
    if (Object.keys(purchaseInfo).length > 0) {
        await commit('updatePurchaseInfo', purchaseInfo);
    }
    if (Object.keys(userInfo).length > 0) {
        await commit('updateUserInfo', userInfo);
    }
}
