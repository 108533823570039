
export default async (commit, query) => {
    const purchaseInfo = {};
    const userInfo = {};

    if (query.has('partnerAccountId')) {
        purchaseInfo.partnerAccountId = query.get('partnerAccountId');
        if (query.has('bundleId')) purchaseInfo.bundleId = query.get('bundleId');
    }
    if (query.has('linkPostedBy')) purchaseInfo.linkPostedBy = query.get('linkPostedBy');
    if (query.has('sourceCode')) purchaseInfo.sourceCode = query.get('sourceCode').toLowerCase();
    if (query.has('partnerName')) purchaseInfo.partnerName = query.get('partnerName').toLowerCase();

    if (query.has('flow')) purchaseInfo.flow = query.get('flow');

    if (query.has('currency')) userInfo.currency = query.get('currency');
    if (query.has('country')) userInfo.country = query.get('country');
    if (query.has('email')) userInfo.email = query.get('email');
    if (query.has('firstName')) userInfo.firstName = query.get('firstName');
    if (query.has('lastName')) userInfo.lastName = query.get('lastName');
    if (query.has('company')) userInfo.company = query.get('company');
    if (query.has('phone')) userInfo.phone = query.get('phone');

    if (query.has('ticket')) {
        const { jwtDecode } = await import('jwt-decode');

        if (query.has('ticket')) {
            const { firstName, lastName, email } = jwtDecode(query.get('ticket'));
            userInfo.firstName = firstName;
            userInfo.lastName = lastName;
            userInfo.email = email;

            await commit('updateReturningCustomer', {casJWT: query.get('ticket')});
        }
    }

    if (Object.keys(purchaseInfo).length > 0) {
        await commit('updatePurchaseInfo', purchaseInfo);
    }
    if (Object.keys(userInfo).length > 0) {
        await commit('updateUserInfo', userInfo);
    }

}
