export default {
    updateCartInfo(state, cartInfo) {
        state.cartInfo = { ...state.cartInfo, ...cartInfo };
    },
    updateTalon(state, value) {
        state.talon = value;
    },
    setSubmitting(state, value) {
        state.orderStatus.submitting = value;
    },
    updateReturningCustomer(state, returningCustomer) {
        state.returningCustomer = { ...state.returningCustomer, ...returningCustomer };
    },
    setSubmittedSuccess(state, value) {
        state.orderStatus.success = value;
    },
    setPasswordValid(state, value) {
        state.passwordIsValid = value;
    },
    setRecaptchaToken(state, value) {
        state.recaptchaToken = value;
    },
    updateUserInfo(state, userInfo) {
        state.userInfo = { ...state.userInfo, ...userInfo };
    },
    updatePurchaseInfo(state, purchaseInfo) {
        state.purchaseInfo = { ...state.purchaseInfo, ...purchaseInfo };
    },
    updateChargebeeInfo(state, chargebeeInfo) {
        state.chargebeeInfo = { ...state.chargebeeInfo, ...chargebeeInfo };
    },
    updateTracking(state, tracking) {
        state.tracking = { ...state.tracking, ...tracking };
    },
    updateRedirectLocation(state, location) {
        state.redirectLocation = location;
    },
    setError(state, error) {
        state.error = { ...state.error, ...error };
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setTestBox(state, value) {
        state.testBox = value;
    },
    setTransactionId(state, value) {
        state.transactionId = value;
    },
};
