import axios from 'axios';

export default async (state, commit, getters) => {
    let payload = {
        instantOn: getters.instantOn,
        tracking: JSON.stringify(state.tracking),
        timezone: state.userInfo.timezone,
        currency: state.userInfo.currency,
    };

    if (!!getters.casJWT) {
        payload = {
            ...payload,
            casJWT: getters.casJWT,
        }
    } else {
        payload = {
            ...payload,
            userInfo: {
                firstName: state.userInfo.firstName,
                lastName: state.userInfo.lastName,
                email: state.userInfo.email,
                ...(state.userInfo.password && {password: state.userInfo.password}),
                phone: state.userInfo.phone,
            },
            ...(state.purchaseInfo.flow && {flow: state.purchaseInfo.flow}),
            talon: state.talon,
            ...(getters.casJWT && {casJWT: getters.casJWT}),
            ...(state.purchaseInfo.partnerAccountId && {partnerAccountId: state.purchaseInfo.partnerAccountId}),
            ...(state.purchaseInfo.linkPostedBy && {linkPostedBy: state.purchaseInfo.linkPostedBy}),
            ...(state.purchaseInfo.bundleId && {bundleId: state.purchaseInfo.bundleId}),
            ...(state.purchaseInfo.sourceCode && {adPartner: state.purchaseInfo.sourceCode}),
            ...(!!getters.recaptchaToken && {
                recaptchaToken: getters.recaptchaToken,
                recaptchaAction: 'FREE_TRIAL_SIGNUP'
            }),
            ...(getters.isTestBox && {requestCampaign: 'architecture.FreeTrialTestBox'}),
            transactionId: getters.transactionId,
            ...(!!state.purchaseInfo.linkId && {linkId: state.purchaseInfo.linkId}),
        };
    }
    let response;
    if (!!getters.casJWT) {
        response = await axios.post('/api/v1/subscription/jwt-trial', {
            ...payload
        });
    } else {
        response = await axios.post('/api/v1/subscription/trial', {
            ...payload
        });
    }

    commit('updateChargebeeInfo', { subscriptionId: response.data.subscriptionId });
    if (getters.isTestBox && !!data.location) {
        commit('updateRedirectLocation', response.data.location);
    }
    if (!getters.isTestBox) {
        commit('updateUserInfo', { userId: response.data.user.id, jwt: response.data.user.jwt });
    }
}
